import React, {useEffect, useState} from "react";
import {Button, Card, CardBody, CardFooter, Typography,} from "@material-tailwind/react";
import {useNavigate} from "react-router-dom";
import axios from "axios";

function Complete() {
    const navigate = useNavigate();
    const [branch, setBranch] = useState(null);

    const getAddress = async () => {
        //localStorage.clear();
        try {
            await axios
                .get(process.env.REACT_APP_API_URL + "/api/v1/getAddress", {
                    withCredentials: true,
                })
                .then((response) => {
                    setBranch(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    navigate("/");
                });
        } catch (e) {
            console.log(e);
        }

        document.cookie = `Authorization=;expires=;path=`; //얘약하고 하면 쿠키 자동 삭제. 다시 예약 시도하지 못하도록 막음.
    };

    useEffect(() => {
        getAddress();
    }, []);

    const handleClick = () => {
        navigate("/");
    };

    return (
        <>
            <div className="flex justify-center items-center rounded-lg lg:overflow-visible">
                <Card className="w-80 mt-6 px-4  pt-4">
                    <CardBody>
                        <Typography variant="small">
                            <div class="">
                                <div
                                    class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-200">
                                    <svg
                                        xmlns="http://www.w3.org/2100/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="lucide lucide-check h-5 w-5 text-green-600 dark:text-green-400"
                                    >
                                        <path d="M20 6 9 17l-5-5"></path>
                                    </svg>
                                </div>
                            </div>
                        </Typography>
                        <Typography className="text-center">
                            <br></br>
                            <p className="font-bold text-green-500">
                                The reservation has been completed.
                            </p>
                        </Typography>

                        <Typography variant="small" className="text-left text-black">
                            <br></br>
                            예약 변경을 원하시면 최소 1일 전에 연락 주시기 바랍니다.
                        </Typography>

                        <Typography variant="small" className="text-left text-black">
                            <br></br>
                            If you wish to change or cancel your reservation, please contact
                            us at least 1 day in advance.
                        </Typography>

                        <Typography variant="small" className="text-left mt-10 text-black">
                            {branch?.address}
                        </Typography>
                        <Typography variant="small" className="text-left text-black">
                            {branch?.phone}
                        </Typography>
                    </CardBody>
                    <CardFooter className="pt-0">
                        <div className="text-center">
                            <Button
                                onClick={handleClick}
                                variant="gradient"
                                size="lg"
                                className="w-full px-3 py-3"
                            >
                                HOME
                            </Button>
                        </div>
                    </CardFooter>
                </Card>
            </div>
        </>
    );
}

export default Complete;
