import {Link} from 'react-router-dom'
import RightPanel from './RightPanel';

function CarSelection() {
    const nextUrl = "/services";


    return (
        <>
            <div>
                <div className="h-10"></div>
                <div className="flex flex-row justify-evenly">
                    <div className="w-96">
                        <svg xmlns="http://www.w3.org/2100/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                             stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"/>
                        </svg>
                    </div>
                    <div className="w-96"></div>
                </div>


                <div className="flex flex-row justify-evenly p-3">
                    <div>
                        <div
                            className="flex flex-col w-96 max-h-full p-6 space-y-2 bg-white border border-gray-200 rounded-lg shadow">

                            <h2 className="text-base mt-5 font-semibold leading-7 text-gray-900 text-sm">자동차를
                                선택하세요.</h2>
                            <div className="flex flex-col space-y-4 mt-4">
                                <div>
                                    <input type="Radio" id="react-option" value="1" name="serviceTime"
                                           className="hidden peer" required=""/>
                                    <label htmlFor="react-option"
                                           className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer  peer-checked:border-blue-600 hover:text-gray-600  peer-checked:text-gray-600 hover:bg-gray-50 ">
                                        <div className="block">
                                            <div className="w-full text-lg font-semibold">Honda CRV EX 2015</div>
                                        </div>
                                    </label>
                                </div>

                                <div>
                                    <input type="Radio" id="flowbite-option" value="2" name="serviceTime"
                                           className="hidden peer"/>
                                    <label htmlFor="flowbite-option"
                                           className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer peer-checked:border-blue-600 hover:text-gray-600  peer-checked:text-gray-600 hover:bg-gray-50">
                                        <div className="block">
                                            <div className="w-full text-lg font-semibold">Mazda Mazda3 2016</div>
                                        </div>
                                    </label>
                                </div>

                                <div className="block sm:hidden fixed bottom-0 bg-white">
                                    <div className="container">
                                        <Link to={nextUrl}
                                              className="flex w-80 justify-center rounded-md bg-gray-600  px-3 py-3 text-lg font-semibold leading-6 text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">다음</Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="w-96">
                        <RightPanel nextUrl={nextUrl}></RightPanel>
                    </div>
                </div>
            </div>
        </>
    )

}

export default CarSelection;