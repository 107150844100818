import React, {useEffect, useState} from "react";
import {Button, Card, CardBody, CardFooter, Typography,} from "@material-tailwind/react";
import {useNavigate} from "react-router-dom";
import axios from "axios";

function Error() {
    const navigate = useNavigate();
    const [branch, setBranch] = useState(null);

    const getAddress = async () => {
        //localStorage.clear();
        try {
            await axios
                .get(process.env.REACT_APP_API_URL + "/api/v1/getAddress", {
                    withCredentials: true,
                })
                .then((response) => {
                    setBranch(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    //navigate("/");
                });
        } catch (e) {
            console.log(e);
        }

        document.cookie = `Authorization=;expires=;path=`; //얘약하고 하면 쿠키 자동 삭제. 다시 예약 시도하지 못하도록 막음.
    };

    useEffect(() => {
        getAddress();
    }, []);

    function handleClick() {
        navigate("/");
    }

    return (
        <>
            <div className="flex justify-center items-center rounded-lg lg:overflow-visible">
                <Card className="w-80 mt-6 px-4  pt-4">
                    <CardBody>
                        <Typography>
                            <div class="">
                                <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-200">
                                    <svg
                                        xmlns="http://www.w3.org/2100/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        class="lucide lucide-check h-5 w-5 text-red-600 dark:text-red-400"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M6 18 18 6M6 6l12 12"
                                        />
                                    </svg>
                                </div>
                            </div>
                        </Typography>

                        <Typography variant="small" className="text-center ">

                            <br></br>
                            <p class="font-bold text-red-600 ">The reservation has failed.</p>
                            <br></br>
                        </Typography>
                        <Typography variant="small" className="text-black">
                            <br></br>
                            <p>
                                {localStorage.getItem("saveErrorMessage")}
                            </p>
                            <p>
                                다른 시간으로 예약을 시도하시거나, 아래 연락처로 연락 주시기
                                바랍니다.
                            </p>
                            <br></br>
                            <p>
                                Please try again or contact us at the following contact
                                information.
                            </p>
                        </Typography>

                        <Typography variant="small" className="text-left mt-10 text-black">
                            {branch?.address}
                        </Typography>
                        <Typography variant="small" className="text-left text-black">
                            {branch?.phone}
                        </Typography>
                    </CardBody>
                    <CardFooter className="pt-0">
                        <div className="text-center">
                            <Button
                                onClick={handleClick}
                                variant="gradient"
                                size="lg"
                                className="w-full px-3 py-3"
                            >
                                HOME
                            </Button>
                        </div>
                    </CardFooter>
                </Card>
            </div>
        </>
    );
}

export default Error;
