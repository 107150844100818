import "./App.css";
import {Link} from "react-router-dom";
import {Button, Typography} from "@material-tailwind/react";

function App() {
    return (
        <>
            <div className="flex justify-center items-center">
                <div className="max-w-xl mt-10 mx-5 my-1 block rounded-lg dark:bg-white">
                    <div className="text-left">
                        <Typography variant="h5" color="blue-gray" className="text-center mb-16">
                            Welcome to No1. Jimmy Auto
                        </Typography>
                        <h2 className="mt-5 font-semibold leading-7 text-gray-900 text-lg text-center">
                            처음 방문 하셨습니까?
                        </h2>
                        <h2 className="mt-5 font-semibold leading-7 text-gray-900 text-lg text-center">
                            Is this your first visit?
                        </h2>
                        <div className="justify-center items-center mt-10 flex flex-row space-x-5 text-sm">
                            <div>
                                <Link to="/signup">
                                    <Button
                                        variant="outlined"
                                        size="md"
                                        className="w-40"
                                    >
                                        YES
                                    </Button>
                                </Link>
                                {/* <Link to="/signup" className="flex w-40 justify-center rounded-md bg-indigo-600 px-20 py-3 text-lg font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">YES</Link> */}
                            </div>
                            <div>
                                <Link to="/signin">
                                    <Button
                                        variant="outlined"
                                        size="md"
                                        className="w-40"
                                    >
                                        NO
                                    </Button>
                                </Link>
                                {/* <Link to="/signin" className="flex w-40 justify-center rounded-md bg-indigo-600 px-20 py-3 text-lg font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">NO</Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default App;
