import {Link} from 'react-router-dom'
import RightPanel from './RightPanel';

function UserSearch() {
    const nextUrl = "/carselection";
    return (
        <>

            <div>
                <div className="h-10"></div>
                <div className="flex flex-row justify-evenly">
                    <div className="w-96">
                        <svg xmlns="http://www.w3.org/2100/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                             stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"/>
                        </svg>
                    </div>
                    <div className="w-96"></div>
                </div>

                <div className="flex flex-row justify-evenly p-3">
                    <div>
                        <div
                            className="flex flex-col w-96 max-h-full p-6 space-y-2 bg-white border border-gray-200 rounded-lg shadow">

                            <h2 className="text-base mt-5 font-semibold leading-7 text-gray-900 text-sm">모바일 번호, Email,
                                Plate number를 입력하십시요.</h2>
                            <div className="content-center mt-1 flex flex-row space-x-2 text-sm">
                                <div className='w-80'>
                                    <input
                                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                        id="username" type="text" placeholder="mobile, email or plate number"></input>
                                </div>

                                <div className='mt-2'>
                                    <Link to={nextUrl}>
                                        <svg xmlns="http://www.w3.org/2100/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth={1.5} stroke="currentColor" className="w-7 h-">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-96">
                        <RightPanel nextUrl={nextUrl}></RightPanel>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserSearch;