import "./App.css";
import React, {useEffect, useState} from "react";
import {Calendar, defaultTheme, Provider, useDateFormatter, useLocale,} from "@adobe/react-spectrum";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {getDayOfWeek, getLocalTimeZone, parseDate, today} from "@internationalized/date";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {dateActions} from "./store/service";
import {Button, Typography} from "@material-tailwind/react";

function Schedule() {
    const navigate = useNavigate();
    const rightProps = {nextUrl: "/carinfo", ready: false};
    const [date, setDate] = React.useState(
        parseDate(new Date(new Date().getTime() + (24 * 60 * 60 * 1000) - (new Date().getTimezoneOffset() * 60000)).toISOString().substring(0, 10))
    );
    let formatter = useDateFormatter({dateStyle: "full"});
    const [searchParams, setSearchParams] = useSearchParams();
    let {locale} = useLocale();

    const [timeSlots, setTimeSlots] = useState([]);
    const [progress, setProgress] = useState(false);
    const [isTimeSelected, setIsTimeSelected] = useState(false);
    const [branch, setBranch] = useState(null);

    const dispatch = useDispatch();
    const dateList = useSelector((state) => state.date);

    const [serviceList, setServiceList] = useState([]);

    const updateServiceList = () => {
        setServiceList(JSON.parse(localStorage.getItem("services")));
    };

    const getAddress = async () => {
        //localStorage.clear();
        try {
            await axios
                .get(process.env.REACT_APP_API_URL + "/api/v1/getAddress", {
                    withCredentials: true,
                })
                .then((response) => {
                    setBranch(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    navigate("/");
                });
        } catch (e) {
            console.log(e);
        }
    };

    const getAvailableTimeslots = async () => {
        const checkboxes = document.getElementsByName("serviceTime");
        for (var i = 0; i < checkboxes.length - 1; i++) {
            checkboxes[i].checked = false;
        }

        try {
            const serviceIDs = [];

            const serviceArray = JSON.parse(localStorage.getItem("services"));

            for (let i = 0; i < serviceArray.length; i++) {
                serviceIDs.push(serviceArray[i].id);
            }

            const month = date.month < 10 ? "0" + date.month : date.month.toString();
            const day = date.day < 10 ? "0" + date.day : date.day.toString();

            const postData = {
                serviceIDs: serviceIDs,
                pickedDate: date.year + "-" + month + "-" + day,
                branchID: 1,
            };

            try {
                setTimeSlots([]);
                setProgress(true);
                await new Promise((resolve) => setTimeout(resolve, 1100));

                await axios
                    .post(
                        process.env.REACT_APP_API_URL + "/api/v1/getAvailableTime",
                        postData,
                        {withCredentials: true}
                    )
                    .then((response) => {
                        setTimeSlots(response.data);
                        setIsTimeSelected(false);
                        updateServiceList();

                        const params = new URLSearchParams(searchParams);
                        params.delete("date");
                        params.delete("time");
                        setProgress(true);
                    })
                    .catch((error) => {
                        console.log(error);
                        navigate("/");
                    });
            } catch (e) {
                console.log(e);
            }
        } catch (error) {
            console.error("Error deleting post:", error);
        }

        setProgress(false);
    };

    useEffect(() => {
        getAvailableTimeslots();
        getAddress();
    }, [date]);

    const timeAddHandler = (timeSlot) => {
        const year = date.year;
        const month = date.month < 10 ? "0" + date.month : date.month.toString();
        const day = date.day < 10 ? "0" + date.day : date.day.toString();
        const date2 = year + "-" + month + "-" + day;
        const dateTimeString = date2 + " " + timeSlot;

        dispatch(dateActions.add(dateTimeString));
        localStorage.setItem("bookingtime", dateTimeString);

        setIsTimeSelected(true);
    };

    const localtionHandler = () => {
        const params = new URLSearchParams(searchParams);
        params.delete("date");
        params.delete("time");
        setSearchParams(params.toString());
    };

    let now = today(getLocalTimeZone());
    //오늘 날짜가 지난 경우 예약 불가
    // 오늘 날짜로 부터 60일 이후 예약 불가 처리
    let disabledRanges = [
        [now.subtract({years: 100}), now.subtract({days: 0})],
        [now.add({days: 60}), now.add({years: 100})],
    ];

    let isDateUnavailable = (date) =>
        getDayOfWeek(date, locale) === 0 ||
        disabledRanges.some(
            (interval) =>
                date.compare(interval[0]) >= 0 && date.compare(interval[1]) <= 0
        );

    return (
        <>
            <div>
                <div className="h-10"></div>

                <div className="flex flex-row justify-evenly">
                    <div className="w-96">
                        <Link
                            to={{
                                pathname: "/services",
                                search:
                                    "?service=" + encodeURIComponent(searchParams.get("service")),
                            }}
                        >
                            <svg
                                xmlns="http://www.w3.org/2100/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
                                />
                            </svg>
                        </Link>
                    </div>
                    <div className="w-96"></div>
                </div>

                <div className="flex flex-row justify-evenly">
                    <div className="flex flex-col w-96 max-h-full  bg-white ">
                        <div className="p-10 w-96">
                            <Provider theme={defaultTheme} colorScheme="light">
                                <Calendar
                                    aria-label="Event date"
                                    value={date}
                                    isDateUnavailable={isDateUnavailable}
                                    onChange={setDate}
                                />
                            </Provider>
                        </div>

                        {progress && (
                            <>
                                <div>
                                    <div className="animate-pulse">
                                        <Typography
                                            as="div"
                                            variant="paragraph"
                                            className="ml-10 mb-2 h-2 w-60 rounded-full bg-gray-300"
                                        >
                                            &nbsp;
                                        </Typography>
                                        <Typography
                                            as="div"
                                            variant="paragraph"
                                            className="ml-10 mb-2 h-2 w-60 rounded-full bg-gray-300"
                                        >
                                            &nbsp;
                                        </Typography>
                                    </div>
                                </div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div>
                                    <div className="animate-pulse">
                                        <Typography
                                            as="div"
                                            variant="paragraph"
                                            className="ml-10 mb-2 h-2 w-60 rounded-full bg-gray-300"
                                        >
                                            &nbsp;
                                        </Typography>
                                        <Typography
                                            as="div"
                                            variant="paragraph"
                                            className="ml-10 mb-2 h-2 w-60 rounded-full bg-gray-300"
                                        >
                                            &nbsp;
                                        </Typography>
                                    </div>
                                </div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div>
                                    <div className="animate-pulse">
                                        <Typography
                                            as="div"
                                            variant="paragraph"
                                            className="ml-10 mb-2 h-2 w-60 rounded-full bg-gray-300"
                                        >
                                            &nbsp;
                                        </Typography>
                                        <Typography
                                            as="div"
                                            variant="paragraph"
                                            className="ml-10 mb-2 h-2 w-60 rounded-full bg-gray-300"
                                        >
                                            &nbsp;
                                        </Typography>
                                    </div>
                                </div>
                            </>
                        )}

                        {timeSlots?.map((slots, index) => (
                            <div className="space-y-2" key={slots.timeSlot}>
                                <input
                                    type="radio"
                                    id={index}
                                    value={slots.timeSlot}
                                    name="serviceTime"
                                    className="hidden peer"
                                    onClick={() => timeAddHandler(slots.timeSlot)}
                                />
                                <label
                                    htmlFor={index}
                                    className="inline-flex items-center text-center ml-7 w-80 h-14 p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer peer-checked:border-gray-900 hover:text-gray-600  peer-checked:text-gray-600 hover:bg-gray-50 "
                                >
                                    <div className="text-sm text-center font-semibold">
                                        {slots.timeSlot}
                                    </div>
                                </label>
                            </div>
                        ))}
                        <div className="h-10"></div>
                        <div className="block sm:hidden fixed bottom-0 bg-white">
                            <div className="container">
                                {/* <Link to={rightProps.nextUrl} className="flex w-80 justify-center rounded-md bg-indigo-600  ml-7 px-3 py-3 text-lg font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">예약완료</Link> */}
                                {isTimeSelected ? (
                                    <Link to="/carinfo">
                                        <Button
                                            variant="gradient"
                                            size="lg"
                                            className="w-80 justify-center ml-7 px-3 py-3"
                                        >
                                            NEXT
                                        </Button>
                                    </Link>
                                ) : (
                                    <Button
                                        disabled
                                        variant="gradient"
                                        size="lg"
                                        className="w-80 justify-center ml-7 px-3 py-3"
                                    >
                                        NEXT
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="w-96">
                        <div
                            className="hidden md:block mt-10 w-96 max-h-full overflow-y-none bg-white border border-gray-200 rounded-lg shadow sm:p-6">
                            <h5 className="mb-3 text-base font-semibold text-gray-900 md:text-xl text-center">
                                Services
                            </h5>
                            <div>No.1 Jimmy Auto</div>
                            <div className="text-gray-500 text-sm mb-8">
                                {branch?.address} {branch?.postalCode}
                            </div>

                            {dateList.length > 1 && (
                                <div className="flex flex-row space-x-4 my-5">
                                    <div>
                                        <svg
                                            xmlns="http://www.w3.org/2100/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                            className="w-5 h-5"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                                            />
                                        </svg>
                                    </div>
                                    <div className="text-sm text-gray-500 truncate">
                                        {dateList.split(" ")[0]}
                                    </div>
                                </div>
                            )}

                            {dateList.length > 1 && (
                                <div className="flex flex-row space-x-4 my-5">
                                    <div>
                                        <svg
                                            xmlns="http://www.w3.org/2100/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                            className="w-5 h-5"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                            />
                                        </svg>
                                    </div>
                                    <div className="text-sm text-gray-500 truncate">
                                        {dateList.split(" ")[1]}
                                    </div>
                                </div>
                            )}

                            {serviceList?.map((itemt, index) => (
                                <>
                                    <div className="flex flex-row space-x-4 my-5">
                                        <div>
                                            <svg
                                                xmlns="http://www.w3.org/2100/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth={1.5}
                                                stroke="currentColor"
                                                className="w-5 h-5"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M21.75 6.75a4.5 4.5 0 0 1-4.884 4.484c-1.076-.091-2.264.071-2.95.904l-7.152 8.684a2.548 2.548 0 1 1-3.586-3.586l8.684-7.152c.833-.686.995-1.874.904-2.95a4.5 4.5 0 0 1 6.336-4.486l-3.276 3.276a3.004 3.004 0 0 0 2.25 2.25l3.276-3.276c.256.565.398 1.192.398 1.852Z"
                                                />
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M4.867 19.125h.008v.008h-.008v-.008Z"
                                                />
                                            </svg>
                                        </div>
                                        <div className="text-sm text-gray-500 truncate">
                                            {itemt.name}
                                        </div>
                                    </div>
                                </>
                            ))}

                            <div className="mt-24 hidden md:block">
                                {isTimeSelected ? (
                                    <Link to="/carinfo">
                                        <Button
                                            variant="gradient"
                                            size="lg"
                                            className="w-[21rem] justify-center px-3 py-3 "
                                        >
                                            NEXT
                                        </Button>
                                    </Link>
                                ) : (
                                    <Button
                                        disabled
                                        variant="gradient"
                                        size="lg"
                                        className="w-[21rem] justify-center px-3 py-3 "
                                    >
                                        NEXT
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Schedule;
